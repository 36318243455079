import { Helmet } from 'react-helmet'
import styles from './ToolPage.module.css'
import Navbar from './componenets/Navbar'
import Footer from './componenets/Footer'
import { FaGlobe } from 'react-icons/fa'
import { FiMail } from 'react-icons/fi'

const ToolPage = ({tool}) => {
  // const tool = TOOLS.filter((tool) => tool?.slug === slug)
  const website = tool?.website?.split('?')[0]+'?ref=spiff.store'
  // console.log(website)
  return (
    <>
    <Helmet>
      <title>{tool.name} | AI Tools</title>
    
    <meta property="og:title" content={`${tool.name} | AI Tools`} />
    <meta property="og:description"
      content={tool.metaDescription} />
    <meta property="og:image" content={tool.imgUrl} />
    <meta property="og:type" content="website" />
    
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={`${tool.name} | AI Tools`} />
    <meta name="twitter:description"
      content={tool.metaDescription} />
    <meta name="twitter:image" content={tool.imgUrl} />
    
    <meta name="description" content={tool.metaDescription} />
    </Helmet>
      <Navbar />
    <div className={styles.card}>

      <img className={styles["card-image"]} src={tool?.imgUrl} alt={tool?.name} />
      <div className={styles["card-content"]}>
        <div className={styles["card-header"]}>
          <h1>{tool?.name}</h1>
          <a style={{display:'flex', alignItems: 'center', gap: '4px'}} href={website}><FaGlobe /> Visit Website</a>
          <a style={{display:'flex', alignItems: 'center', gap: '4px'}} href={'mailto:'+tool?.email}><FiMail /> Contact</a>
        </div>
        <p className={styles["badge"]}>{tool?.category}</p>
        {/* <p>{tool?.description}</p> */}
        {/* <pre>{tool?.longDescription}</pre> */}
        <div dangerouslySetInnerHTML={{__html: tool?.longDescription}}>

        </div>

        {tool?.videoUrl?.length ? 
        <div>
          <h2>Demo</h2>
          <video autoPlay={true} muted controls loop width={'100%'} style={{borderRadius: '20px', maxHeight: '50vh'}}>
          <source src={tool?.videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        :
        ""
        }
      </div>
    </div>
      {/* <footer>
        <p>© {new Date().getFullYear()} Spiff.store</p>
      </footer> */}

    <Footer />

    </>
  )
}

export default ToolPage
